// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-algemene-voorwaarden-jsx": () => import("./../../../src/pages/algemene-voorwaarden.jsx" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-jsx" */),
  "component---src-pages-babyconnect-jsx": () => import("./../../../src/pages/babyconnect.jsx" /* webpackChunkName: "component---src-pages-babyconnect-jsx" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-form-jsx": () => import("./../../../src/pages/form.jsx" /* webpackChunkName: "component---src-pages-form-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-over-mij-jsx": () => import("./../../../src/pages/over-mij.jsx" /* webpackChunkName: "component---src-pages-over-mij-jsx" */),
  "component---src-pages-privacy-verklaring-jsx": () => import("./../../../src/pages/privacy-verklaring.jsx" /* webpackChunkName: "component---src-pages-privacy-verklaring-jsx" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-zwangerconnect-jsx": () => import("./../../../src/pages/zwangerconnect.jsx" /* webpackChunkName: "component---src-pages-zwangerconnect-jsx" */),
  "component---src-templates-baby-connect-template-js": () => import("./../../../src/templates/babyConnectTemplate.js" /* webpackChunkName: "component---src-templates-baby-connect-template-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-zwanger-connect-template-js": () => import("./../../../src/templates/zwangerConnectTemplate.js" /* webpackChunkName: "component---src-templates-zwanger-connect-template-js" */)
}

